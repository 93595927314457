import React from 'react'
import './Leadership.css'
import quote from '../../Assets/quote.svg'
import leader1 from '../../Assets/leader1.png'
import leader2 from '../../Assets/leader2.png'
import leader3 from '../../Assets/leader3.png'
import leader4 from '../../Assets/leader4.png'

const Leadership = () => {
    return (
        <div className='mesh-bg'>
            <div className='container'>
                <div className='position-relative mb-5'>
                    <h2 className='line'>leadership</h2>
                </div>
            </div>
            <div className='position-relative custom-width'>
                <img className="leader-img" src={leader1} alt='leader' />
                <div className='custom-card leader-card'>
                <img  className="leader-img-new" src={leader1} alt='leader' />
                    <img className='mb-2'  src={quote} />
                    <p>Bishop Dr. AnilKumar John Servand is the Chairman of Baldwin Methodist Educational Society and heads the Bangalore Regional Conference of the Methodist Church in India. He is committed to excellence in church life as well as in the field of education. Bishop Dr. Anilkumar Servand has spent considerable years of his ministry working with zeal and dedication helping the Christian Ministry. He comes with rich and vast professional and ministerial experience.</p>

                    <p>As the minister of the Methodist Church in India (MCI) he held many responsible positions in MCI and other Institutions, serving as the Professor and Principal of the Methodist Bible Seminary, Gujarat, The Director of Methodist Technical Institute at Baroda, Gujarat and as the Pastor and District Superintendent for several years prior to becoming the Bishop of MCI. Currently Bishop Dr. AnilKumar John Servand is the Bishop of Bangalore Regional Conference of MCI, Mumbai Regional Conference of MCI, Chennai Regional Conference of MCI and Bareilly Regional Conference of MCI. He is also the Chairperson of Medical Council of MCI and Women Council of MCI, in the capacity of Bishop, he is the Chief Acharya of Sattal Christian Ashram, Sattal, Nainital. Our Chairman’s commitment to spiritually enrich the church life and improve the standard of education is unparalleled. </p>
                    <p> Our Chairman is a wellspring of knowledge, wisdom and experience. His pioneering leadership and in-depth decisions have elevated the school’s prestige and stature to greater heights. Our Chairman’s progressive reforms have facilitated numerous productive transitions. His futuristic vision has expanded the base of academic orientation through a broader spectrum of development. </p>                    
                    <p>Bishop Dr. AnilKumar John Servand is a visionary, a personality who strives to make a difference. He is a man of principles and is task oriented, a public figure loved by all.</p>					
				  <p>We are honoured to have Dr. AnilKumar John Servand as the Chairman of Baldwin Educational Society who is an accomplished educationalist and a dynamic leader with extensive experience both in India and abroad.</p>
				<div className='about-leader'>
                        <p>Bishop Dr. AnilKumar John Servand</p>
                        <p>chairman</p>
                    </div>
                </div>
            </div>
           <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
            <div className='position-relative custom-width custom-width2'>
                <img className="leader-img"  src={leader2} alt='leader' />
                <div className='custom-card leader-card2'>
                <img  className="leader-img-new" src={leader2} alt='leader' />
                    <img className='mb-2' src={quote} />
                    <p>“Education is the most powerful weapon which you can use to change the world.”– Nelson Mandela</p>

                    <p>Our commitment at Baldwin Girls’ High School is to provide a safe and intellectually challenging environment that will empower students to become innovative thinkers, creative problem solvers and inspired learners prepared to thrive in the fast changing world.</p>
                    <div className='about-leader'>
                        <p>Mrs. Asha Margaret Das </p>
                        <p>Principal</p>
                    </div>
                </div>
            </div>

            <div className='position-relative custom-width'>
                <img className="leader-img"  src={leader3} alt='leader' />
                <div className='custom-card leader-card'>
                <img  className="leader-img-new" src={leader3} alt='leader' />

                    <img className='mb-2' src={quote} />
                    <p>Education is simply the soul of a society as it passes from one generation to another." - G.K. Chesterton</p>

                    <p>In a constantly changing world, education must evolve to meet new challenges through collaboration, innovation, and growth. Since 1880, Baldwin Girls' High School has excelled in producing responsible citizens who drive societal change. The school's updated learning strategies empower students to address global issues and navigate the world beyond their immediate surroundings.
                    </p>
                    <div className='about-leader'>
                        <p>MRS. Phebe Sheela Rani</p>
                        <p>manager</p>
                    </div>
                </div>
            </div>

            {/* <div className='position-relative  custom-width2'>
                <img  className="leader-img" src={leader4} alt='leader' />
                <div className='custom-card leader-card2'>
                    <img  className="leader-img-new" src={leader4} alt='leader' />
                    <img className='mb-2' src={quote} />
                    <p>Schooling is a shared commitment of teachers, students, and parents. At Baldwin Girls' High School, we foster a nurturing environment where learning thrives. </p>

                    <p>Our focus extends beyond academics and we strive to develop
well-rounded individuals through positive partnerships between home and school. Together, we inspire each Baldwinian to discover and unleash their potential, shaping a brighter future.</p>


                    <div className='about-leader'>
                        <p>MRS. Sujatha Catherine</p>
                        <p>vice Principal</p>
                    </div>
                </div>
            </div> */}

        </div>


    )
}

export default Leadership